import { Form, Input } from "components";
import { useEffect, useRef, useState } from "react";
import Textarea from "components/UI/Textarea/Textarea";
import { Api, useApiCall } from "api";
import { Inquiry, validateEmail, validateInputNotEmpty } from "utils";
import { InputErrors } from "utils";

import "./ContactForm.scss";

const initialErrorState = {
  name: false,
  email: false,
  message: false,
};

const ContactForm = () => {
  const nameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const messageRef = useRef<HTMLTextAreaElement>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const [formErrors, setFormErrors] = useState(initialErrorState);

  const {
    makeRequest: sendMessage,
    loading: messageLoading,
    response: messageResponse,
    error: messageError,
  } = useApiCall();

  useEffect(() => {
    if (messageError === null) {
      formRef.current?.reset();
    }
  }, [messageResponse, messageError]);

  const validateForm = () => {
    let errors = { ...initialErrorState };
    let isValidationOk = true;

    if (validateInputNotEmpty(nameRef.current?.value) === false) {
      errors.name = true;
    }

    if (validateEmail(emailRef.current?.value) === false) {
      errors.email = true;
    }

    if (validateInputNotEmpty(messageRef.current?.value) === false) {
      errors.message = true;
    }

    Object.values(errors).forEach((value, index) => {
      if (value === true) {
        isValidationOk = false;
        return;
      }
    });

    setFormErrors(errors);
    return isValidationOk;
  };

  const sendMessageRequest = () => {
    const inquiry: Inquiry = {
      name: nameRef.current?.value,
      email: emailRef.current?.value,
      message: messageRef.current?.value,
    };

    sendMessage(Api.ENDPOINTS.SUPPORT, {}, inquiry, Api.METHODS.POST);
  };

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (validateForm() === false) {
      return;
    }

    sendMessageRequest();
  };

  return (
    <>
      <div className="inquiry-form">
        <Form
          ref={formRef}
          onSubmit={handleFormSubmit}
          errorText={messageError && messageError !== "" ? messageError : ""}
          isLoading={messageLoading}
          submitButtonName="Send Feedback"
          childrenAfterSubmitButton={
            messageResponse?.status === 201 && <div className="success-message">Support ticket sent!</div>
          }
        >
          <Input
            name="Name"
            ref={nameRef}
            validationFunc={validateInputNotEmpty}
            errorText={InputErrors.EMPTY_NAME}
            formError={formErrors.name}
            onChange={() => {
              setFormErrors({ ...formErrors, name: false });
            }}
            autoComplete="name"
          />

          <Input
            name="Email"
            ref={emailRef}
            type="email"
            validationFunc={validateEmail}
            errorText={InputErrors.INCORRECT_EMAIL}
            formError={formErrors.email}
            onChange={() => {
              setFormErrors({ ...formErrors, email: false });
            }}
            autoComplete="email"
          />

          <Textarea
            name="Message"
            ref={messageRef}
            validationFunc={validateInputNotEmpty}
            errorText={InputErrors.EMPTY_MESSAGE}
            formError={formErrors.message}
            onChange={() => {
              setFormErrors({ ...formErrors, message: false });
            }}
            autoComplete="off"
          />
        </Form>
      </div>
    </>
  );
};

export default ContactForm;
