import { config } from "api";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import "./Legal.scss";

const initialLegalState = {
  terms: false,
  privacy: false,
  eula: false,
};

const FormulaAnalyzerLegal = () => {
  const [legalActive, setLegalActive] = useState(initialLegalState);
  const location = useLocation();

  let legalText = <></>;
  if (
    location.pathname === config.APP_PATHS.ANALYSTHIVE_FA_LEGAL ||
    location.pathname === config.APP_PATHS.ANALYSTHIVE_FA_LEGAL_TERMS
  ) {
    legalText = (
      <>
        <b>Terms of Use</b>
        <br />
        Last updated 2023-12-05
        <ol>
          <li>
            Introduction
            <ul>
              <li>Explanation of the Add-in and its Purpose</li>
              <li>Agreement to the Terms</li>
            </ul>
          </li>

          <li>
            User Agreement and Acceptance
            <ul>
              <li>Agreement to the Terms Upon Usage</li>
            </ul>
          </li>
          <li>
            Limitation of Liability
            <ul>
              <li>Disclaimer of Warranties</li>
              <li>Limitation of Liability</li>
            </ul>
          </li>
          <li>
            Data Handling and Privacy
            <ul>
              <li>Reference to the Comprehensive Privacy Policy Document</li>
            </ul>
          </li>
          <li>
            Updates and Modifications
            <ul>
              <li>Automatic Updates and Modifications</li>
              <li>Notification of Changes</li>
            </ul>
          </li>
          <li>
            Termination and Suspension
            <ul>
              <li>Termination of User Access</li>
              <li>Suspension of Add-in Usage</li>
            </ul>
          </li>
          <li>Intellectual Property Rights</li>

          <ul>
            <li>Ownership and Licensing</li>
          </ul>
          <li>
            Miscellaneous
            <ul>
              <li>Severability Clause</li>
              <li>Governing Law and Jurisdiction</li>
            </ul>
          </li>
        </ol>
        <h3>Introduction</h3>
        <p>
          Welcome to the "Formula Analyzer" add-in for Microsoft Excel, a part of our suite of tools offered under the
          "AnalystHive" product name. This document outlines the terms and conditions that govern your use of this
          add-in.
          <br />
          By accessing and using the "Formula Analyzer" add-in, you agree to comply with these terms and conditions. If
          you do not agree with any part of these terms, you should not use the add-in.
          <br /> This document is a legal agreement between you ("User" or "you") and StumbrasTech, governing your use
          of the "Formula Analyzer" add-in.
          <br /> Please read these terms carefully before using the add-in. Your use of the add-in indicates your
          acknowledgment and acceptance of these terms.
          <br /> Should you have any questions or concerns regarding these terms, please contact us at&nbsp;
          <b>support@stumbrastech.com</b>.
        </p>
        <h3>User Agreement and Acceptance</h3>
        <ol>
          <p>
            <li>
              <b>Acceptance of Terms</b>: By accessing or using the "Formula Analyzer" add-in for Microsoft Excel ("the
              Add-in"), you agree to be bound by these Terms of Use, all applicable laws, and regulations. If you do not
              agree with any of these terms, you are prohibited from using or accessing this Add-in.
            </li>

            <li>
              <b>Changes to Terms</b>: StumbrasTech reserves the right, at its sole discretion, to modify or replace any
              part of these Terms of Use. It is your responsibility to check these terms periodically for changes. Your
              continued use of or access to the Add-in following the posting of any changes constitutes acceptance of
              those changes.
            </li>

            <li>
              <b>Accessing the Add-in</b>: Access to the Add-in is permitted on a temporary basis. StumbrasTech reserves
              the right to withdraw or amend the Add-in without notice. From time to time, access to the Add-in may be
              restricted due to maintenance or other reasons.
            </li>

            <li>
              <b>User Eligibility</b>: The Add-in is intended for use by individuals who are at least the legal age of
              majority in their jurisdiction. By using the Add-in, you represent and warrant that you meet all
              eligibility requirements.
            </li>

            <li>
              <b>User Account</b>: Some features of the Add-in may require you to register for an account. You are
              responsible for maintaining the confidentiality of your account and password and for restricting access to
              your computer or device. You agree to accept responsibility for all activities that occur under your
              account or password.
            </li>
          </p>
        </ol>
        <h3>Limitation of Liability</h3>
        <ol>
          <p>
            <li>
              <b>No Warranty or Liability</b>: The "Formula Analyzer" add-in is provided "as is," without warranty of
              any kind, express or implied. StumbrasTech makes no representations or warranties of any kind concerning
              the Add-in or its use, and shall not be liable for any damages resulting from the use of the Add-in.
            </li>

            <li>
              <b>No Liability for Errors</b>: While every effort has been made to ensure the accuracy and reliability of
              the Add-in, it should be noted that errors may occur. StumbrasTech disclaims all liability and
              responsibility for any errors or inaccuracies in the content or functionality of the Add-in.
            </li>

            <li>
              <b>Use at Your Own Risk</b>: The use of the Add-in is done at your own discretion and risk, and you will
              be solely responsible for any damage to your computer system or loss of data that results from the use of
              the Add-in.
            </li>

            <li>
              <b>No Liability for Third-Party Content</b>: The Add-in may include links to third-party websites or
              content. StumbrasTech does not endorse or assume any responsibility for any such third-party content or
              websites. Your interactions with these third parties are solely between you and the third party, and
              StumbrasTech shall not be liable for any damages or losses arising from such interactions.
            </li>

            <li>
              <b>Limitation of Damages</b>: In no event shall StumbrasTech be liable for any direct, indirect,
              incidental, special, consequential, or punitive damages, including but not limited to damages for loss of
              profits, goodwill, use, data, or other intangible losses resulting from the use or inability to use the
              Add-in.
            </li>
          </p>
        </ol>
        <h3>Data Handling and Privacy</h3>
        <ol>
          <p>
            <li>
              <b>Data Collection</b>: The "Formula Analyzer" add-in may collect anonymous usage data through Google
              Analytics to improve the performance and user experience of the add-in. This data is used solely for
              analytical purposes and does not include any personally identifiable information from Excel files or user
              identification.
            </li>

            <li>
              <b>Permissions</b>: The add-in requires read and write permissions for Excel solely to retrieve formula
              references and facilitate the intended features. No data from Excel files is collected or transmitted over
              the internet by the add-in.
            </li>

            <li>
              <b>User-Provided Information</b>: Users have the option to provide feedback within the add-in. Any
              information shared voluntarily by users, such as feedback or comments, will be collected and used to
              improve the add-in's functionality and user experience.
            </li>

            <li>
              <b>Third-Party Services</b>: The add-in may contain links or access third-party services that have their
              own privacy policies. Users are advised to review the privacy policies of these third-party services as
              StumbrasTech does not have control over their practices.
            </li>

            <li>
              <b>Security Measures</b>: StumbrasTech implements industry-standard security measures to safeguard any
              collected data from unauthorized access, disclosure, alteration, or destruction.
            </li>

            <li>
              <b>Data Retention</b>: Collected data is retained only for as long as necessary to fulfill the purposes
              for which it was collected or as required by law.
            </li>
          </p>
        </ol>
        <h3>Updates and Modifications</h3>
        <ol>
          <p>
            <li>
              <b>Automatic Updates</b>: The "Formula Analyzer" add-in may undergo periodic updates to enhance
              functionality, address security vulnerabilities, or introduce new features. These updates are
              automatically delivered through the Microsoft AppSource Store to ensure the add-in's optimal performance.
            </li>

            <li>
              <b>Purpose of Updates</b>: Updates are primarily aimed at improving user experience, fixing bugs, or
              enhancing the add-in's capabilities. Users will be informed about significant updates, feature changes, or
              modifications through notification prompts within the add-in.
            </li>

            <li>
              <b>Consent to Updates</b>: By using the "Formula Analyzer" add-in, users agree to receive automatic
              updates and modifications. Continued use of the add-in after updates implies acceptance of any
              modifications or changes made to the add-in's functionality or terms of use.
            </li>

            <li>
              <b>User Notifications</b>: Users may receive notifications within the add-in interface informing them
              about available updates. These notifications will provide information about the nature of the updates and
              their impact on the add-in's functionality.
            </li>
          </p>
        </ol>
        <h3>Termination and Suspension</h3>
        <ol>
          <p>
            <li>
              <b>User Agreement Termination</b>: Users can terminate their agreement with the "Formula Analyzer" add-in
              at any time by uninstalling or discontinuing its use.
            </li>

            <li>
              <b>Violation of Terms</b>: Failure to comply with the terms of use, privacy policy, or any related
              policies may result in the immediate suspension or termination of the user's access to the add-in's
              features and services.
            </li>

            <li>
              <b>Suspension of Services</b>: The add-in reserves the right to suspend or terminate user access
              temporarily or permanently in the event of suspected misuse, security concerns, or violation of terms
              without prior notice.
            </li>

            <li>
              <b>Effect of Termination</b>: Upon termination, users should refrain from further use of the add-in and
              its services. The termination does not nullify or affect clauses pertaining to liability, indemnity, or
              data handling after termination.
            </li>

            <li>
              <b>Request for Termination</b>: Users seeking to terminate their agreement or account can reach out to the
              provided support contact for guidance on the termination process.
            </li>
          </p>
        </ol>
        <h3>Intellectual Property Rights</h3>
        <ol>
          <p>
            <li>
              <b>Ownership</b>: The "Formula Analyzer" add-in, including all content, features, trademarks, logos, and
              designs, remains the sole property of StumbrasTech and its licensors. Users do not acquire any rights or
              licenses to the add-in beyond those expressly granted.
            </li>

            <li>
              <b>Usage Restrictions</b>: Users are prohibited from reproducing, modifying, distributing, or creating
              derivative works from any part of the add-in without explicit authorization from StumbrasTech. Any
              unauthorized use may result in legal action.
            </li>

            <li>
              <b>User Contributions</b>: Any suggestions, feedback, or contributions provided by users to improve the
              add-in shall become the property of StumbrasTech, granting the company the right to utilize, modify, or
              distribute them without any compensation or obligations to the user.
            </li>

            <li>
              <b>Third-party Content</b>: The add-in may include third-party content or links. All third-party materials
              and trademarks are the property of their respective owners, and their usage is subject to their terms and
              conditions.
            </li>

            <li>
              <b>Reporting Violations</b>: Users are encouraged to report any instances of copyright or intellectual
              property infringement found within the add-in. Such reports can be directed to the provided contact
              information.
            </li>
          </p>
        </ol>
        <h3>Miscellaneous</h3>
        <ol>
          <p>
            <li>
              <b>Severability Clause</b>: If any provision within these Terms of Use is found to be invalid, illegal, or
              unenforceable, that provision shall be deemed severed from the Terms without affecting the validity,
              legality, or enforceability of the remaining provisions.
            </li>

            <li>
              <b>Governing Law and Jurisdiction</b>: These Terms of Use shall be governed by and construed in accordance
              with the laws of Lithuania, without regard to its conflict of law principles. Any dispute arising from or
              relating to these Terms shall be subject to the exclusive jurisdiction of the courts located in Lithuania,
              and each party hereby submits to the personal jurisdiction of such courts.
            </li>
          </p>
        </ol>
      </>
    );
  } else if (location.pathname === config.APP_PATHS.ANALYSTHIVE_FA_LEGAL_PRIVACY) {
    legalText = (
      <>
        <b>Privacy Policy</b>
        <br />
        Last updated 2023-12-05
        <ol>
          <li>
            Introduction
            <ul>
              <li>Overview of the Privacy Policy</li>
              <li>Scope of Application</li>
            </ul>
          </li>
          <li>
            Information Collection and Usage
            <ul>
              <li>Types of Information Collected</li>
              <li>Collection Methods</li>
              <li>Purpose of Information Collection</li>
              <li>Data Usage and Processing</li>
            </ul>
          </li>
          <li>
            Information Sharing, Disclosure, and Security
            <ul>
              <li>Sharing with Third Parties</li>
              <li>Disclosure for Legal Reasons</li>
              <li>Data Security Measures</li>
            </ul>
          </li>
          <li>
            Data Retention and User Rights
            <ul>
              <li>Retention Periods</li>
              <li>Managing Cookie Preferences</li>
              <li>Explanation of Rights</li>
            </ul>
          </li>
          <li>
            Updates to this Privacy Policy and Contact Information
            <ul>
              <li>Notification of Changes</li>
              <li>Reviewing Updated Privacy Policy</li>
              <li>How to Reach Us for Questions and Concerns</li>
            </ul>
          </li>
        </ol>
        <h3>Introduction</h3>
        <p>
          Welcome to the Privacy Policy for the Formula Analyzer add-in ("the Add-in"), developed by AnalystHive. This
          Privacy Policy governs the collection, usage, and handling of information obtained through the use of the
          Formula Analyzer add-in within Microsoft Excel.
          <br />
          This Privacy Policy outlines the types of information collected and how we use, share, and protect this
          information. By using the Formula Analyzer add-in, you agree to the collection and use of information as
          described in this policy.
          <br />
          This Privacy Policy applies solely to the information collected by the Formula Analyzer add-in and does not
          extend to any other information collected through other services or third-party applications.
          <br />
          Please read this Privacy Policy carefully to understand our practices regarding your information and how we
          treat it. If you have any questions or concerns regarding our Privacy Policy, please contact us using the
          details provided in the "Contact Information" section at the end of this document.
          <br />
          Your continued use of the Formula Analyzer add-in constitutes your acceptance of this Privacy Policy and any
          updates or changes made to it. If you do not agree with this Privacy Policy, please refrain from using the
          Formula Analyzer add-in.
        </p>
        <h3>Information Collection and Usage</h3>
        <ol>
          <p>
            <li>
              <b>Types of Information Collected</b>: The Formula Analyzer add-in collects and processes limited
              information primarily provided by users. This information is solely limited to user-provided feedback
              submitted through the add-in interface. No other personal information, browsing history, or data from
              Excel files is collected or transmitted.
            </li>
            <li>
              <b>Collection Methods</b>: Information is collected when users voluntarily submit feedback or comments
              within the Formula Analyzer add-in. This feedback may include suggestions, comments, or other information
              relevant to the add-in's functionality or user experience.
            </li>
            <li>
              <b>Purpose of Information Collection</b>: The collected feedback serves the sole purpose of improving the
              Formula Analyzer add-in. This information aids in understanding user requirements, identifying areas for
              enhancement, and refining the add-in's functionality to better meet user needs.
            </li>
            <li>
              <b>Data Usage and Processing</b>: The user-provided feedback collected within the Formula Analyzer add-in
              is used solely for internal purposes. It is utilized to analyze user opinions, trends, and preferences to
              enhance the add-in's features and usability.
            </li>
            We do not sell, rent, or trade any user-provided information to third parties.
          </p>
        </ol>
        <h3>Information Sharing, Disclosure, and Security</h3>
        <ol>
          <p>
            <li>
              <b>Sharing with Third Parties</b>: The Formula Analyzer add-in utilizes Google Analytics for the
              collection of anonymous usage data to analyze add-in performance and usage trends. This data is used
              solely for internal purposes to enhance the add-in's functionality and user experience. Google Analytics
              may collect information about add-in usage, such as the number of users and interactions within the
              add-in, but it does not collect any personal information or data from Excel files.
            </li>
            <li>
              <b>Disclosure for Legal Reasons</b>: We may disclose user-provided information if required by law or when
              such action is necessary to comply with legal obligations, protect our rights, or respond to lawful
              requests from public authorities.
            </li>
            <li>
              <b>Data Security Measures</b>: We are committed to maintaining the security and confidentiality of the
              information collected through the Formula Analyzer add-in. We employ industry-standard security measures
              to safeguard this information against unauthorized access, alteration, disclosure, or destruction.
            </li>
            However, please note that no method of transmission or electronic storage is entirely secure. While we
            strive to use commercially acceptable means to protect your information, we cannot guarantee its absolute
            security. For any concerns or questions please reach out to <b>support@stumbrastech.com</b>
          </p>
        </ol>
        <h3>Data Retention and User Rights</h3>
        <ol>
          <p>
            <li>
              <b>Retention Periods</b>: The user-provided feedback collected through the Formula Analyzer add-in is
              retained for as long as necessary to fulfill the purposes outlined in the "Information Collection and
              Usage" section of this Privacy Policy. We retain this information to analyze and improve the add-in's
              functionality and user experience.
            </li>
            <li>
              <b>Managing Cookie Preferences</b>: The Formula Analyzer add-in utilizes Google Analytics, which may use
              cookies and similar tracking technologies to collect anonymous usage data. Users can manage their cookie
              preferences and opt-out of Google Analytics by adjusting their browser settings or using Google's
              Analytics Opt-out Browser Add-on.
            </li>
            <li>
              <b>Explanation of Rights</b>: As the information collected through the Formula Analyzer add-in is limited
              to user-provided feedback without any personally identifiable data, specific user rights related to
              personal information, such as access, rectification, or erasure, are not applicable in this context.
            </li>
          </p>
        </ol>
        <h3>Updates to this Privacy Policy and Contact Information</h3>
        <ol>
          <p>
            <li>
              <b>Notification of Changes</b>: We reserve the right to update or modify this Privacy Policy periodically
              to reflect changes in our practices or legal requirements. Any updates or revisions to this Privacy Policy
              will be reflected with a revised "Last Updated" date at the top of the document. Users will be notified of
              significant changes via notice within the Formula Analyzer add-in or through other appropriate means.
            </li>
            <li>
              <b>Reviewing Updated Privacy Policy</b>: It is recommended that users review this Privacy Policy
              periodically to stay informed about how we collect, use, and protect the information collected through the
              Formula Analyzer add-in. Continued use of the add-in after any modifications to this Privacy Policy
              constitutes acceptance of those changes.
            </li>
            <li>
              <b>How to Reach Us for Questions and Concerns</b>: If you have any questions, concerns, or feedback
              regarding this Privacy Policy or the data practices of the Formula Analyzer add-in, please contact us via
              email at <b>support@stumbrastech.com</b>. We will endeavor to respond to your inquiries promptly.
            </li>
          </p>
        </ol>
      </>
    );
  } else if (location.pathname === config.APP_PATHS.ANALYSTHIVE_FA_LEGAL_EULA) {
    legalText = (
      <>
        <iframe
          title="eula"
          className="eula"
          src="https://support.office.com/client/61994a3b-2c87-41c4-a88d-a6455efa362d"
        />
      </>
    );
  }

  useEffect(() => {
    if (
      location.pathname === config.APP_PATHS.ANALYSTHIVE_FA_LEGAL ||
      location.pathname === config.APP_PATHS.ANALYSTHIVE_FA_LEGAL_TERMS
    ) {
      setLegalActive({ ...initialLegalState, terms: true });
    } else if (location.pathname === config.APP_PATHS.ANALYSTHIVE_FA_LEGAL_PRIVACY) {
      setLegalActive({ ...initialLegalState, privacy: true });
    } else if (location.pathname === config.APP_PATHS.ANALYSTHIVE_FA_LEGAL_EULA) {
      setLegalActive({ ...initialLegalState, eula: true });
    }
  }, [location.pathname]);

  return (
    <div className="legal container">
      <div className="legal-links">
        <Link
          className={`legal-link ${legalActive.terms && "legal-link-active"}`}
          to={config.APP_PATHS.ANALYSTHIVE_FA_LEGAL_TERMS}
        >
          Terms
        </Link>
        <Link
          className={`legal-link ${legalActive.privacy && "legal-link-active"}`}
          to={config.APP_PATHS.ANALYSTHIVE_FA_LEGAL_PRIVACY}
        >
          Privacy
        </Link>
        <Link
          className={`legal-link ${legalActive.eula && "legal-link-active"}`}
          to={config.APP_PATHS.ANALYSTHIVE_FA_LEGAL_EULA}
        >
          EULA
        </Link>
      </div>
      <div className="legal-text">{legalText}</div>
    </div>
  );
};

export default FormulaAnalyzerLegal;
