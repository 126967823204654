import { config } from "api";
import { Credits, Header } from "components";
import { DocumentAnalyzer, DocumentAnalyzerLegal, FormulaAnalyzer, FormulaAnalyzerLegal, Home, Support } from "pages";
import { Navigate, Route, Routes } from "react-router-dom";
import CookieConsent from "react-cookie-consent";

import "./App.scss";

const App = () => {
  return (
    <div className="app">
      <Header />
      <Routes>
        <Route index path={config.APP_PATHS.MAIN} element={<Home />} />
        {/* Formula Analyzer */}
        <Route path={config.APP_PATHS.ANALYSTHIVE_FA} element={<FormulaAnalyzer />} />
        <Route path={config.APP_PATHS.ANALYSTHIVE_FA_TEMP} element={<FormulaAnalyzer />} />
        <Route path={config.APP_PATHS.ANALYSTHIVE_FA_LEGAL} element={<FormulaAnalyzerLegal />} />
        <Route path={config.APP_PATHS.ANALYSTHIVE_FA_LEGAL_EULA} element={<FormulaAnalyzerLegal />} />
        <Route path={config.APP_PATHS.ANALYSTHIVE_FA_LEGAL_PRIVACY} element={<FormulaAnalyzerLegal />} />
        <Route path={config.APP_PATHS.ANALYSTHIVE_FA_LEGAL_TERMS} element={<FormulaAnalyzerLegal />} />
        {/* Document Analyzer */}
        <Route path={config.APP_PATHS.ANALYSTHIVE_DA} element={<DocumentAnalyzer />} />
        <Route path={config.APP_PATHS.ANALYSTHIVE_DA_TEMP} element={<DocumentAnalyzer />} />
        <Route path={config.APP_PATHS.ANALYSTHIVE_DA_LEGAL} element={<DocumentAnalyzerLegal />} />
        <Route path={config.APP_PATHS.ANALYSTHIVE_DA_LEGAL_EULA} element={<DocumentAnalyzerLegal />} />
        <Route path={config.APP_PATHS.ANALYSTHIVE_DA_LEGAL_PRIVACY} element={<DocumentAnalyzerLegal />} />
        <Route path={config.APP_PATHS.ANALYSTHIVE_DA_LEGAL_TERMS} element={<DocumentAnalyzerLegal />} />
        <Route path={config.APP_PATHS.SUPPORT} element={<Support />} />

        <Route path="*" element={<Navigate to={config.APP_PATHS.MAIN} />} />
      </Routes>
      <Credits />
      <CookieConsent
        buttonText="Understood"
        disableStyles={true}
        containerClasses="cookie-consent-body"
        contentClasses="cookie-consent-content"
        buttonClasses="cookie-consent-btn"
      >
        This website uses cookies to ensure the best user experience.
      </CookieConsent>
    </div>
  );
};

export default App;
