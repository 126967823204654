import { config } from "api";
import { Link } from "react-router-dom";
import logoFA from "assets/icons/FormulaAnalysis_logo.svg";
import MSAppSource from "assets/icons/MS_AppSource.png";
import { ReactSVG } from "react-svg";

import "./FormulaAnalyzer.scss";

const FormulaAnalyzer = () => {
  return (
    <div className="formula-analyzer container">
      <div className="headers-container">
        <ReactSVG className="logo" src={logoFA} />
        <h1>Formula Analyzer</h1>
      </div>

      <p>
        Introducing 'Formula Analyzer' - an indispensable Excel add-in designed to empower financial analysts and
        professionals seeking precision in formula analysis. This tool serves as your go-to assistant in comprehending
        intricate formula references within your spreadsheets.
      </p>

      <p>
        Explore the nuances of your formulas effortlessly as 'Formula Analyzer' generates clear insights by displaying
        formula references accompanied by easily identifiable names from your table's rows and columns. Dive deep into
        your formulas, seamlessly previewing references right within Excel's interface.
      </p>

      <p>
        Tailor your experience with customizable settings that enable you to choose the preferred naming conventions for
        references, whether it's addresses, row names, column names, or the values themselves. Elevate your analytical
        capabilities, unravel complexities, and streamline your formula analysis process with 'Formula Analyzer' - the
        ultimate ally for precision-driven financial analysis in Excel.
      </p>

      <br />
      <p>
        Try out "Formula Analyzer" for <b>free</b>!
      </p>
      <a href="https://appsource.microsoft.com/en/product/office/WA200006318">
        <img className="app-source-link" alt="microsoft-app-source-store" src={MSAppSource} />
      </a>

      <div className="fa-links">
        <Link className="fa-link" to={config.APP_PATHS.SUPPORT}>
          Support
        </Link>
        <Link className="fa-link" to={config.APP_PATHS.ANALYSTHIVE_FA_LEGAL}>
          Legal
        </Link>
      </div>
    </div>
  );
};

export default FormulaAnalyzer;
