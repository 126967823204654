import logo from "assets/icons/stumbrastech_logo.svg";

import "./Credits.scss";
import { ReactSVG } from "react-svg";

const Credits = () => {
  return (
    <div className="credits">
      <a href="https://dominykasstumbras.com">
        <ReactSVG className="logo" src={logo} />
        StumbrasTech
      </a>
    </div>
  );
};

export default Credits;
