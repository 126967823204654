import "./Home.scss";

const Home = () => {
  return (
    <div className="container">
      <div className="headers-container centered">
        <h1>Welcome to AnalystHive</h1>
      </div>
      <div className="headers-container centered">
        <h1>Your one-stop destination for finance productivity</h1>
      </div>

      <h2>
        Empowering Financial Analysts, Credit Analysts, Bankers, Investors, and anyone navigating financial documents!
      </h2>

      <p>
        Discover a comprehensive array of tools, data solutions, and more, tailored to meet the intricate demands of
        financial analysis. AnalystHive is your go-to hub for a range of resources, catering to professionals handling
        crucial financial documents such as income statements, company data reports, and beyond.
      </p>

      <p>
        Explore our suite of productivity-enhancing add-ins, meticulously crafted to streamline your analytical workflow
        and elevate your efficiency. Whether you're diving into credit analysis, delving deep into banking intricacies,
        or making informed investment decisions, AnalystHive equips you with the tools and resources necessary to excel
        in your field.
      </p>

      <p>
        Uncover the power of AnalystHive - your trusted platform delivering unparalleled assistance to those navigating
        the complexities of financial analysis.
      </p>
    </div>
  );
};

export default Home;
