import { config } from "api";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import "./Legal.scss";

const initialLegalState = {
  terms: false,
  privacy: false,
  eula: false,
};

const DocumentAnalyzerLegal = () => {
  const [legalActive, setLegalActive] = useState(initialLegalState);
  const location = useLocation();

  let legalText = <></>;
  if (
    location.pathname === config.APP_PATHS.ANALYSTHIVE_DA_LEGAL ||
    location.pathname === config.APP_PATHS.ANALYSTHIVE_DA_LEGAL_TERMS
  ) {
    legalText = (
      <>
        <b>Terms of Use</b>
        <br />
        Last updated 2024-02-05
        <ol>
          <li>
            Introduction
            <ul>
              <li>Explanation of the Add-in and its Purpose</li>
              <li>Agreement to the Terms</li>
            </ul>
          </li>

          <li>
            User Agreement and Acceptance
            <ul>
              <li>Agreement to the Terms Upon Usage</li>
              <li>User Login with Microsoft Account</li>
            </ul>
          </li>
          <li>
            Limitation of Liability
            <ul>
              <li>Disclaimer of Warranties</li>
              <li>Limitation of Liability</li>
            </ul>
          </li>
          <li>
            Data Handling and Privacy
            <ul>
              <li>Reference to the Comprehensive Privacy Policy Document</li>
              <li>Document Upload and Storage</li>
            </ul>
          </li>
          <li>
            Updates and Modifications
            <ul>
              <li>Automatic Updates and Modifications</li>
              <li>Notification of Changes</li>
            </ul>
          </li>
          <li>
            Termination and Suspension
            <ul>
              <li>Termination of User Access</li>
              <li>Suspension of Add-in Usage</li>
            </ul>
          </li>
          <li>Intellectual Property Rights</li>

          <ul>
            <li>Ownership and Licensing</li>
          </ul>
          <li>
            Miscellaneous
            <ul>
              <li>Severability Clause</li>
              <li>Governing Law and Jurisdiction</li>
              <li>Commercial Use and Licensing</li>
              <li>Contact Information for Support</li>
            </ul>
          </li>
        </ol>
        <h3>Introduction</h3>
        <p>
          Welcome to "Document Analyzer", an innovative add-in designed to streamline table extraction from financial
          documents directly into Excel. By accessing or using this add-in, you agree to be bound by the terms and
          conditions outlined herein. Please read these Terms of Use carefully before accessing or using the Document
          Analyzer add-in.
          <h3>Explanation of the Add-in and its Purpose</h3>
          Document Analyzer is a powerful tool developed to simplify the process of extracting tables from financial
          documents, such as PDFs, and seamlessly integrating them into Excel spreadsheets. The add-in employs advanced
          technology to automatically detect tables within uploaded documents, providing users with a convenient way to
          analyze financial data directly within Excel.
        </p>
        <h3>Agreement to the Terms</h3>
        <p>
          By accessing or using the Document Analyzer add-in, you acknowledge that you have read, understood, and agree
          to be bound by these Terms of Use. If you do not agree to these terms, please refrain from using the add-in.
          Your continued use of the Document Analyzer add-in constitutes acceptance of these terms and any modifications
          thereto.
        </p>
        <h3>User Agreement and Acceptance</h3>
        <p>
          Agreement to the Terms Upon Usage By accessing or using the Document Analyzer add-in, you agree to be bound by
          these Terms of Use. Your use of the add-in constitutes your acceptance of these terms, including any future
          modifications or updates. If you do not agree to these terms, please refrain from using the Document Analyzer
          add-in. User Login with Microsoft Account Document Analyzer offers the convenience of single sign-on using
          your Microsoft account. By logging in to the add-in with your Microsoft account credentials, you authorize
          Document Analyzer to access and use your email address for identification purposes only. Your email address
          will not be disclosed to any third parties, and all user data is handled in accordance with our Privacy
          Policy.
        </p>
        <h3>Limitation of Liability</h3>
        <ol>
          <p>
            <li>
              <b>No Warranty or Liability</b>: The "Document Analyzer" add-in is provided "as is," without warranty of
              any kind, express or implied. StumbrasTech makes no representations or warranties of any kind concerning
              the Add-in or its use, and shall not be liable for any damages resulting from the use of the Add-in.
            </li>

            <li>
              <b>No Liability for Errors</b>: While every effort has been made to ensure the accuracy and reliability of
              the Add-in, it should be noted that errors may occur. StumbrasTech disclaims all liability and
              responsibility for any errors or inaccuracies in the content or functionality of the Add-in.
            </li>

            <li>
              <b>Use at Your Own Risk</b>: The use of the Add-in is done at your own discretion and risk, and you will
              be solely responsible for any damage to your computer system or loss of data that results from the use of
              the Add-in.
            </li>

            <li>
              <b>No Liability for Third-Party Content</b>: The Add-in may include links to third-party websites or
              content. StumbrasTech does not endorse or assume any responsibility for any such third-party content or
              websites. Your interactions with these third parties are solely between you and the third party, and
              StumbrasTech shall not be liable for any damages or losses arising from such interactions.
            </li>

            <li>
              <b>Limitation of Damages</b>: In no event shall StumbrasTech be liable for any direct, indirect,
              incidental, special, consequential, or punitive damages, including but not limited to damages for loss of
              profits, goodwill, use, data, or other intangible losses resulting from the use or inability to use the
              Add-in.
            </li>
          </p>
        </ol>
        <h3>Data Handling and Privacy</h3>
        <ol>
          <h3>Reference to the Comprehensive Privacy Policy Document</h3>
          <p>
            <li>
              <b>Data Collection</b>: The "Document Analyzer" add-in may collect anonymous usage data through Google
              Analytics to improve the performance and user experience of the add-in. This data is used solely for
              analytical purposes and does not include any personally identifiable information from Excel files or user
              identification.
            </li>

            <li>
              <b>Permissions</b>: The add-in requires read and write permissions for Excel solely to retrieve tables
              that already been exported to Excel Worksheet and export new tables that have been analyzed from the
              document.
            </li>

            <li>
              <b>User-Provided Information</b>: Users have the option to provide feedback within the add-in. Any
              information shared voluntarily by users, such as feedback or comments, will be collected and used to
              improve the add-in's functionality and user experience.
            </li>

            <li>
              <b>Third-Party Services</b>: The add-in may contain links or access third-party services that have their
              own privacy policies. Users are advised to review the privacy policies of these third-party services as
              StumbrasTech does not have control over their practices.
            </li>

            <li>
              <b>Security Measures</b>: StumbrasTech implements industry-standard security measures to safeguard any
              collected data from unauthorized access, disclosure, alteration, or destruction.
            </li>

            <li>
              <b>Data Retention</b>: Collected data is retained only for as long as necessary to fulfill the purposes
              for which it was collected or as required by law.
            </li>
          </p>
        </ol>
        <h3>Data Handling and Privacy</h3>
        <h3>Reference to the Comprehensive Privacy Policy Document</h3>
        <p>
          Your privacy is important to us. Please refer to our Privacy Policy for information on how we collect, use,
          and disclose personal information in connection with your use of the Document Analyzer add-in. By using the
          add-in, you acknowledge that you have read and agree to the terms of our Privacy Policy.
        </p>
        <h3>Document Upload and Storage</h3>
        <p>
          Document Analyzer allows users to upload financial documents for table analysis. Uploaded documents are
          securely stored to ensure data security and integrity. StumbrasTech does not disclose uploaded documents to
          any third parties and utilizes industry-standard security measures to protect user data.
        </p>
        <h3>Updates and Modifications</h3>
        <ol>
          <p>
            <li>
              <b>Automatic Updates</b>: The "Document Analyzer" add-in may undergo periodic updates to enhance
              functionality, address security vulnerabilities, or introduce new features. These updates are
              automatically delivered through the Microsoft AppSource Store to ensure the add-in's optimal performance.
            </li>

            <li>
              <b>Purpose of Updates</b>: Updates are primarily aimed at improving user experience, fixing bugs, or
              enhancing the add-in's capabilities. Users will be informed about significant updates, feature changes, or
              modifications through notification prompts within the add-in.
            </li>

            <li>
              <b>Consent to Updates</b>: By using the "Document Analyzer" add-in, users agree to receive automatic
              updates and modifications. Continued use of the add-in after updates implies acceptance of any
              modifications or changes made to the add-in's functionality or terms of use.
            </li>

            <li>
              <b>User Notifications</b>: Users may receive notifications within the add-in interface informing them
              about available updates. These notifications will provide information about the nature of the updates and
              their impact on the add-in's functionality.
            </li>
          </p>
        </ol>
        <h3>Termination and Suspension</h3>
        <ol>
          <p>
            <li>
              <b>Termination of User Access</b>
            </li>
            <p>
              StumbrasTech reserves the right to terminate or suspend your access to the Document Analyzer add-in at any
              time, with or without cause, and without prior notice. This may include, but is not limited to, situations
              where StumbrasTech believes that your use of the add-in violates these Terms of Use or is harmful to other
              users or the integrity of the add-in.
            </p>
            <li>
              <b>Suspension of Add-in Usage</b>
            </li>
            <p>
              In addition to termination of user access, StumbrasTech may suspend the availability or functionality of
              the Document Analyzer add-in temporarily or indefinitely, with or without notice, in cases where
              maintenance, updates, or other operational reasons require such suspension.
            </p>
            <li>
              <b>User Agreement Termination</b>: Users can terminate their agreement with the "Document Analyzer" add-in
              at any time by uninstalling or discontinuing its use.
            </li>

            <li>
              <b>Violation of Terms</b>: Failure to comply with the terms of use, privacy policy, or any related
              policies may result in the immediate suspension or termination of the user's access to the add-in's
              features and services.
            </li>

            <li>
              <b>Suspension of Services</b>: The add-in reserves the right to suspend or terminate user access
              temporarily or permanently in the event of suspected misuse, security concerns, or violation of terms
              without prior notice.
            </li>

            <li>
              <b>Effect of Termination</b>: Upon termination, users should refrain from further use of the add-in and
              its services. The termination does not nullify or affect clauses pertaining to liability, indemnity, or
              data handling after termination.
            </li>

            <li>
              <b>Request for Termination</b>: Users seeking to terminate their agreement or account can reach out to the
              provided support contact for guidance on the termination process.
            </li>
          </p>
        </ol>
        <h3>Intellectual Property Rights</h3>
        <ol>
          <p>
            <li>
              <b>Ownership and Licensing</b>
              <p>
                All intellectual property rights associated with the Document Analyzer add-in, including but not limited
                to copyrights, trademarks, and patents, are the property of StumbrasTech (Dominykas Stumbras) or its
                licensors. By using the Document Analyzer add-in, you acknowledge and agree that you do not acquire any
                ownership rights to the add-in or its contents. StumbrasTech (Dominykas Stumbras) grants you a limited,
                non-exclusive, non-transferable license to use the Document Analyzer add-in for your personal purposes.
                This license does not permit you to modify, reproduce, distribute, or create derivative works based on
                the add-in, nor does it grant you any rights to the underlying source code. For corporate or
                professional use of the Document Analyzer add-in, please contact support@stumbrastech.com to inquire
                about acquiring a commercial license.
              </p>
            </li>
            <li>
              <b>Ownership</b>: The "Document Analyzer" add-in, including all content, features, trademarks, logos, and
              designs, remains the sole property of StumbrasTech (Dominykas Stumbras) and its licensors. Users do not
              acquire any rights or licenses to the add-in beyond those expressly granted.
            </li>

            <li>
              <b>Usage Restrictions</b>: Users are prohibited from reproducing, modifying, distributing, or creating
              derivative works from any part of the add-in without explicit authorization from StumbrasTech. Any
              unauthorized use may result in legal action.
            </li>

            <li>
              <b>User Contributions</b>: Any suggestions, feedback, or contributions provided by users to improve the
              add-in shall become the property of StumbrasTech, granting the company the right to utilize, modify, or
              distribute them without any compensation or obligations to the user.
            </li>

            <li>
              <b>Third-party Content</b>: The add-in may include third-party content or links. All third-party materials
              and trademarks are the property of their respective owners, and their usage is subject to their terms and
              conditions.
            </li>

            <li>
              <b>Reporting Violations</b>: Users are encouraged to report any instances of copyright or intellectual
              property infringement found within the add-in. Such reports can be directed to the provided contact
              information.
            </li>
          </p>
        </ol>
        <h3>Miscellaneous</h3>
        <ol>
          <p>
            <li>
              <b>Severability Clause</b>: If any provision within these Terms of Use is found to be invalid, illegal, or
              unenforceable, that provision shall be deemed severed from the Terms without affecting the validity,
              legality, or enforceability of the remaining provisions.
            </li>

            <li>
              <b>Governing Law and Jurisdiction</b>: These Terms of Use shall be governed by and construed in accordance
              with the laws of Lithuania, without regard to its conflict of law principles. Any dispute arising from or
              relating to these Terms shall be subject to the exclusive jurisdiction of the courts located in Lithuania,
              and each party hereby submits to the personal jurisdiction of such courts.
            </li>
          </p>
        </ol>
      </>
    );
  } else if (location.pathname === config.APP_PATHS.ANALYSTHIVE_DA_LEGAL_PRIVACY) {
    legalText = (
      <>
        <b>Privacy Policy</b>
        <br />
        Last updated 2024-02-05
        <ol>
          <li>
            Introduction
            <ul>
              <li>Overview of the Privacy Policy</li>
              <li>Scope of Application</li>
            </ul>
          </li>
          <li>
            Information Collection and Usage
            <ul>
              <li>Types of Information Collected</li>
              <li>Collection Methods</li>
              <li>Purpose of Information Collection</li>
              <li>Data Usage and Processing</li>
            </ul>
          </li>
          <li>
            Information Sharing, Disclosure, and Security
            <ul>
              <li>Sharing with Third Parties</li>
              <li>Disclosure for Legal Reasons</li>
              <li>Data Security Measures</li>
            </ul>
          </li>
          <li>
            Data Retention and User Rights
            <ul>
              <li>Retention Periods</li>
              <li>Managing Cookie Preferences</li>
              <li>Explanation of Rights</li>
            </ul>
          </li>
          <li>
            Updates to this Privacy Policy and Contact Information
            <ul>
              <li>Notification of Changes</li>
              <li>Reviewing Updated Privacy Policy</li>
              <li>How to Reach Us for Questions and Concerns</li>
            </ul>
          </li>
        </ol>
        <h3>Introduction</h3>
        <p>
          Welcome to the Privacy Policy for the "Document Analyzer" add-in ("the Add-in"), developed by StumbrasTech.
          This Privacy Policy governs the collection, usage, and handling of information obtained through the use of the
          Document Analyzer add-in within Microsoft Excel.
          <br />
          This Privacy Policy outlines the types of information collected and how we use, share, and protect this
          information. By using the Document Analyzer add-in, you agree to the collection and use of information as
          described in this policy.
          <br />
          This Privacy Policy applies solely to the information collected by the Document Analyzer add-in and does not
          extend to any other information collected through other services or third-party applications.
          <br />
          Please read this Privacy Policy carefully to understand our practices regarding your information and how we
          treat it. If you have any questions or concerns regarding our Privacy Policy, please contact us using the
          details provided in the "Contact Information" section at the end of this document.
          <br />
          Your continued use of the Document Analyzer add-in constitutes your acceptance of this Privacy Policy and any
          updates or changes made to it. If you do not agree with this Privacy Policy, please refrain from using the
          Document Analyzer add-in.
        </p>
        <h3>Information Collection and Usage</h3>
        <ol>
          <p>
            <b>Types of Information Collected</b>
            <ol>
              <li>
                <b>User Email:</b> When you use the Document Analyzer add-in, your email address associated with your
                Microsoft account may be collected for user authentication purposes. This information is securely stored
                and is not disclosed to third parties.
              </li>
              <li>
                <b>Uploaded Documents:</b> Documents uploaded to the Document Analyzer add-in for table analysis are
                securely stored in compliance with industry standards. Uploaded documents are not disclosed to third
                parties.
              </li>
              <li>
                <b>Bug Reports and Performance Metadata:</b> The add-in may collect bug reports and performance metadata
                to enhance system performance and address any issues. This data is anonymized and used for analytical
                purposes only.
              </li>
            </ol>
            <b>Collection Methods</b>
            <ol>
              <li>
                <b>User Login:</b> User emails are collected through the Microsoft account single sign-on for user
                authentication.
              </li>
              <li>
                <b>Document Upload:</b> Documents are collected when users upload them for table analysis.
              </li>
              <li>
                <b>Bug Reports:</b> Users may voluntarily submit bug reports or performance metadata.
              </li>
            </ol>
            <b>Purpose of Information Collection</b>
            <ol>
              <li>
                <b>User Authentication:</b> User emails are used for authentication purposes to provide a personalized
                experience within the add-in.
              </li>
              <li>
                <b>Document Analysis:</b> Uploaded documents are analyzed for table extraction within the add-in.
              </li>
              <li>
                <b>Improvement of Services:</b> Bug reports and performance metadata are used to enhance the performance
                and address any issues with the Document Analyzer add-in.
              </li>
            </ol>
            <b>Data Usage and Processing</b>
            All collected data is processed securely and is not disclosed to third parties. StumbrasTech adheres to
            industry-standard security measures to protect the confidentiality and integrity of user information. We do
            not sell, rent, or trade any user-provided information to third parties.
          </p>
        </ol>
        <h3>Information Sharing, Disclosure, and Security</h3>
        <ol>
          <p>
            <li>
              <b>Sharing with Third Parties</b>: The information collected by the Document Analyzer add-in is not shared
              with third parties. StumbrasTech does not disclose user information to external entities unless required
              by law or with explicit user consent.
            </li>
            <li>
              <b>Disclosure for Legal Reasons</b>: We may disclose user-provided information if required by law or when
              such action is necessary to comply with legal obligations, protect our rights, or respond to lawful
              requests from public authorities.
            </li>
            <li>
              <b>Data Security Measures</b>: In certain circumstances, StumbrasTech may be required to disclose user
              information to comply with legal obligations or respond to lawful requests from public authorities.
              However, such disclosures will only be made when necessary and in accordance with applicable laws and
              regulations.
            </li>
            StumbrasTech is committed to protecting the security of user information. We implement industry-standard
            security measures to safeguard against unauthorized access, disclosure, alteration, or destruction of data.
            These measures include encryption, access controls, and regular security audits to ensure the integrity of
            our systems. However, please note that no method of transmission or electronic storage is entirely secure.
            While we strive to use commercially acceptable means to protect your information, we cannot guarantee its
            absolute security. For any concerns or questions please reach out to <b>support@stumbrastech.com</b>
          </p>
        </ol>
        <h3>Data Retention and User Rights</h3>
        <ol>
          <p>
            <li>
              <b>Retention Periods</b>: User data collected by the Document Analyzer add-in is retained only for as long
              as necessary to fulfill the purposes outlined in this privacy policy. Once the data is no longer required
              for its intended purpose, it will be securely deleted or anonymized.
            </li>
            <li>
              <b>Managing Cookie Preferences</b>: The Document Analyzer add-in utilizes Google Analytics, which may use
              cookies and similar tracking technologies to collect anonymous usage data. Users can manage their cookie
              preferences and opt-out of Google Analytics by adjusting their browser settings or using Google's
              Analytics Opt-out Browser Add-on.
            </li>
            <li>
              <b>Explanation of Rights</b>: As the information collected through the Document Analyzer add-in is limited
              to user-provided feedback without any personally identifiable data, specific user rights related to
              personal information, such as access, rectification, or erasure, are not applicable in this context.
            </li>
          </p>
        </ol>
        <h3>Updates to this Privacy Policy and Contact Information</h3>
        <ul>
          <p>
            Right to Access: Users have the right to request access to their personal information collected by the
            add-in. Right to Rectification: Users can request the correction of inaccurate or incomplete personal
            information. Right to Erasure: Users can request the deletion of their personal information under certain
            circumstances. Right to Restriction of Processing: Users can request the restriction of processing of their
            personal information in certain situations. Right to Data Portability: Users have the right to receive their
            personal information in a structured, commonly used, and machine-readable format. To exercise these rights
            or inquire about any aspect of this privacy policy, users can contact StumbrasTech at the email address
            provided in the "Contact Information" section.
            <li>
              <b>How to Reach Us for Questions and Concerns</b>: If you have any questions, concerns, or feedback
              regarding this Privacy Policy or the data practices of the Document Analyzer add-in, please contact us via
              email at <b>support@stumbrastech.com</b>. We will endeavor to respond to your inquiries promptly.
            </li>
          </p>
        </ul>
      </>
    );
  } else if (location.pathname === config.APP_PATHS.ANALYSTHIVE_DA_LEGAL_EULA) {
    legalText = (
      <>
        <iframe
          title="eula"
          className="eula"
          src="https://support.office.com/client/61994a3b-2c87-41c4-a88d-a6455efa362d"
        />
      </>
    );
  }

  useEffect(() => {
    if (
      location.pathname === config.APP_PATHS.ANALYSTHIVE_DA_LEGAL ||
      location.pathname === config.APP_PATHS.ANALYSTHIVE_DA_LEGAL_TERMS
    ) {
      setLegalActive({ ...initialLegalState, terms: true });
    } else if (location.pathname === config.APP_PATHS.ANALYSTHIVE_DA_LEGAL_PRIVACY) {
      setLegalActive({ ...initialLegalState, privacy: true });
    } else if (location.pathname === config.APP_PATHS.ANALYSTHIVE_DA_LEGAL_EULA) {
      setLegalActive({ ...initialLegalState, eula: true });
    }
  }, [location.pathname]);

  return (
    <div className="legal container">
      <div className="legal-links">
        <Link
          className={`legal-link ${legalActive.terms && "legal-link-active"}`}
          to={config.APP_PATHS.ANALYSTHIVE_DA_LEGAL_TERMS}
        >
          Terms
        </Link>
        <Link
          className={`legal-link ${legalActive.privacy && "legal-link-active"}`}
          to={config.APP_PATHS.ANALYSTHIVE_DA_LEGAL_PRIVACY}
        >
          Privacy
        </Link>
        <Link
          className={`legal-link ${legalActive.eula && "legal-link-active"}`}
          to={config.APP_PATHS.ANALYSTHIVE_DA_LEGAL_EULA}
        >
          EULA
        </Link>
      </div>
      <div className="legal-text">{legalText}</div>
    </div>
  );
};

export default DocumentAnalyzerLegal;
