import { config } from "api";
import { Link } from "react-router-dom";
import logoDA from "assets/icons/DocumentAnalysis_logo.svg";
import MSAppSource from "assets/icons/MS_AppSource.png";
import { ReactSVG } from "react-svg";

import "./DocumentAnalyzer.scss";

const DocumentAnalyzer = () => {
  return (
    <div className="formula-analyzer container">
      <div className="headers-container">
        <ReactSVG className="logo" src={logoDA} />
        <h1>Document Analyzer</h1>
      </div>

      <p>
        Introducing 'Document Analyzer' - your all-in-one solution for effortlessly extracting tables from financial
        documents directly into Excel. Streamline your workflow and enhance productivity as you seamlessly integrate
        financial data from PDF documents into your Excel spreadsheets.
      </p>
      <p>
        With 'Document Analyzer,' uploading and analyzing financial documents has never been easier. Simply upload your
        PDF files, and let the add-in automatically detect and display tables within the Excel sidebar. Instantly view
        tables in your worksheet, eliminating the need for manual data entry and tedious formatting.
      </p>
      <p>
        Harness the power of automatic table detection, or take control with intuitive manual selection tools. Easily
        refine and edit detected tables using built-in snipping tools, ensuring accuracy and precision in your data
        extraction process.
      </p>

      <br />
      <p>
        Try out "Document Analyzer" for <b>free</b>!
      </p>
      <a href="https://appsource.microsoft.com/en-us/product/office/WA200006561">
        <img className="app-source-link" alt="microsoft-app-source-store" src={MSAppSource} />
      </a>

      <div className="fa-links">
        <Link className="fa-link" to={config.APP_PATHS.SUPPORT}>
          Support
        </Link>
        <Link className="fa-link" to={config.APP_PATHS.ANALYSTHIVE_DA_LEGAL}>
          Legal
        </Link>
      </div>
    </div>
  );
};

export default DocumentAnalyzer;
