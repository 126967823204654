import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import ContactForm from "./ContactForm/ContactForm";
import { useState } from "react";

import "./Contacts.scss";

const Contacts = () => {
  const [linkCopied, setLinkCopied] = useState(false);

  return (
    <section id="contacts" className="section">
      <div className="contacts-container">
        <div className="contacts-form">
          <ContactForm />
        </div>
        <div className="contacts-data">
          {linkCopied && <span className="clipboard-msg">Email copied!</span>}
          <div
            className="contact copy-clipboard"
            onClick={() => {
              navigator.clipboard.writeText("support@stumbrastech.com");
              setLinkCopied(true);
            }}
          >
            <div className="contact-icon">
              <FontAwesomeIcon icon={icon({ name: "envelope" })} className="contacts-icon" />
            </div>
            <div className="contact-details">
              <p className="email">support@stumbrastech.com</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contacts;
