import { ReactSVG } from "react-svg";
import logo from "assets/icons/logo.svg";
import logoDA from "assets/icons/DocumentAnalysis_logo.svg";
import logoFA from "assets/icons/FormulaAnalysis_logo.svg";

import "./Header.scss";
import { Link } from "react-router-dom";
import { config } from "api";

const Header = () => {
  return (
    <nav className="header">
      <div className="header-container">
        <div className="header-logo">
          <Link className="logo-link" to={config.APP_PATHS.MAIN}>
            <ReactSVG className="logo" src={logo} />
            <h1>AnalystHive</h1>
          </Link>
        </div>
        <div className="header-links">
          <Link className="header-link" to={config.APP_PATHS.ANALYSTHIVE_DA}>
            <ReactSVG className="logo header-logo-fa" src={logoDA} />
            Document Analyzer
          </Link>
          <Link className="header-link" to={config.APP_PATHS.ANALYSTHIVE_FA}>
            <ReactSVG className="logo header-logo-fa" src={logoFA} />
            Formula Analyzer
          </Link>
          <Link className="header-link" to={config.APP_PATHS.SUPPORT}>
            Support
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Header;
