// API configuration (base urls, endpoints, app paths)
export const config = {
  API_BASE_URL_DEV: "https://excel.api.analysthive.com/",
  API_BASE_URL_PROD: "https://excel.api.analysthive.com/",
  API_ENDPOINTS: {
    SUPPORT: "api/analysthive/support/",
    LEGAL: "api/analysthive/legal/",
  },
  APP_PATHS: {
    MAIN: "/",
    ANALYSTHIVE_FA: "/FormulaAnalyzer",
    ANALYSTHIVE_FA_TEMP: "/formula-analyzer",
    ANALYSTHIVE_FA_LEGAL: "/FormulaAnalyzer/Legal",
    ANALYSTHIVE_FA_LEGAL_TERMS: "/FormulaAnalyzer/Legal/Terms",
    ANALYSTHIVE_FA_LEGAL_EULA: "/FormulaAnalyzer/Legal/EULA",
    ANALYSTHIVE_FA_LEGAL_PRIVACY: "/FormulaAnalyzer/Legal/Privacy",
    ANALYSTHIVE_DA: "/DocumentAnalyzer",
    ANALYSTHIVE_DA_TEMP: "/document-analyzer",
    ANALYSTHIVE_DA_LEGAL: "/DocumentAnalyzer/Legal",
    ANALYSTHIVE_DA_LEGAL_TERMS: "/DocumentAnalyzer/Legal/Terms",
    ANALYSTHIVE_DA_LEGAL_EULA: "/DocumentAnalyzer/Legal/EULA",
    ANALYSTHIVE_DA_LEGAL_PRIVACY: "/DocumentAnalyzer/Legal/Privacy",
    SUPPORT: "/Support",
  },
};
