export const DEFAULT_REQUEST_ERROR = "Something went wrong, please try again. (500)";

export enum InputErrors {
  EMPTY_EMAIL = "Please provide a valid email",
  EMPTY_NAME = "Please provide a name",
  EMPTY_MESSAGE = "Please provide a message",
  INCORRECT_EMAIL = "Please provide a valid email",
}

export enum LegalTextType {
  TERMS = 0,
  PRIVACY = 1,
  EULA = 2,
}
